import copy from "copy-to-clipboard";

/**
 * Displays the snippet code on the page
 */
export const displayCode = ( code: string ): void => {
    const codeElement = document.getElementById( "code" );

    if( codeElement ) {
        codeElement.innerText = code;
    }
};

/**
 * Inserts the snippet id into the flagging link so that Google Forms prefills that field
 */
export const prefillFlaggingForm = ( snippetId: string ): void => {
    const flagElement = document.getElementById( "flag-button" );

    if( !flagElement ) {
        return;
    }

    const href = flagElement.getAttribute( "href" );

    if( !href ) {
        return;
    }

    flagElement.setAttribute( "href", href + snippetId );
};

/**
 * Removes the loading spinner
 */
export const removeSpinner = (): void => {
    document.getElementById( "loader" )?.remove();
    document.body.classList.remove( "is-loading" );
};

/**
 * Changes all editor links to point to the right place
 */
export const setEditorLinkURLs = ( snippetId: string, language: string ): void => {
    const href = process.env.EDITOR_URL?.
        split( "{id}" ).join( snippetId ).
        split( "{language}" ).join( language || "snippet" ) || "";

    document.querySelectorAll( ".editor-link" ).forEach( link => {
        link.setAttribute( "href", href );
    });
};

/**
 * Sets up the buttons that open and close the info page.
 * The info-open class handles displaying and hiding with CSS.
 */
export const setToggleInfoPage = (): void => {
    document.getElementById( "info-button" )?.addEventListener( "click", () => {
        document.body.classList.add( "info-open" );
    });

    document.getElementById( "close-info-button" )?.addEventListener( "click", () => {
        document.body.classList.remove( "info-open" );
    });
};

/**
 * Sets up the copy button to copy code to clipboard
 */
let copyNotificationTimer: ReturnType<typeof setTimeout>;

export const setupCopyButton = ( code: string ): void => {
    const copyButton = document.getElementById( "copy-button" );

    if( !copyButton ) {
        return;
    }

    copyButton.addEventListener( "click", () => {
        copy( code );
        copyButton.classList.add( "copied" );
        clearTimeout( copyNotificationTimer );
        copyNotificationTimer = setTimeout( () => {
            copyButton.classList.remove( "copied" );
        }, 3000 );
    });
};

/**
 * Shows an error message
 */
export const showError = ( message: string ): void => {
    const errorContainer = document.createElement( "div" );
    errorContainer.classList.add( "has-text-danger" );
    errorContainer.innerText = message;

    document.body.innerHTML = "";
    document.body.appendChild( errorContainer );
};

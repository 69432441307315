import {
    displayCode,
    prefillFlaggingForm,
    removeSpinner,
    setEditorLinkURLs,
    // setToggleInfoPage,
    setupCopyButton,
    showError
} from "./display";
import { fetchSnippet } from "./get";
import { getSnippetId } from "./parseurl";

/**
 * Main routine
 */
const showSnippet = async(): Promise<void> => {
    const snippetId = getSnippetId();

    if( !snippetId ) {
        throw new Error( "No snippet id given" );
    }

    const data = await fetchSnippet( snippetId );

    if( !data || ( !( "code" in data ) && !( "error" in data ) ) ) {
        // generic fetch error
        throw new Error( "Could not fetch snippet contents" );
    }

    if( "error" in data ) {
        throw new Error( data.error );
    }

    // remove the loading spinner
    removeSpinner();

    // modify the page with the data we now have
    displayCode( data.code );
    prefillFlaggingForm( snippetId );
    setEditorLinkURLs( snippetId, data.language );
    // setToggleInfoPage();
    setupCopyButton( data.code );
};

showSnippet().catch( e => {
    showError( e.message );
    console.error( e );
});

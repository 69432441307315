const SNIPPET_ID_MIN_LENGTH = 6;

/**
 * Gets the snippet id from the URL
 */
export const getSnippetId = (): string | null => {
    const URLParams = new URLSearchParams( window.location.search );
    const id = URLParams.get( "id" );

    if( !id || id.length < SNIPPET_ID_MIN_LENGTH ) {
        return null;
    }

    return id;
};

import { SnippetData, SnippetFetchError } from "./types/snippets";

/**
 * Gets the snippet data from the API
 */
export const fetchSnippet = async( id: string ): Promise<SnippetData | SnippetFetchError | null> => {
    const url = process.env.API_GET_URL?.split( "{id}" ).join( id );

    if( !url ) {
        return null;
    }

    try {
        const request = await fetch( url );
        const data = await request.json();

        return data || null;
    }
    catch( e ) {
        console.error( e );
        return null;
    }
};